<template>
<div v-if="label" class="meta">
    <label :for="id">{{ label }}</label>
</div>
<div class="field">
    <div v-if="$slots.flag" class="flag">
        <slot name="flag" />
    </div>
    <input
        :id="id"
        :type="type"
        :value="fieldValue"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on:input="emitInputEvents"
        v-on:keyup="$emit('keyup', $event)"
        v-on:keydown="$emit('keydown', $event)"
        v-on:focus="$emit('focus', $event)"
        v-on:blur="$emit('blur', $event)"
    >
    <div v-if="$slots.adornments" class="adornments">
        <slot name="adornments" />
    </div>
</div>
<div v-if="$slots.hints" class="hints">
    <slot name="hints" />
</div>
<div v-if="$slots.extra" class="extra">
    <slot name="extra" />
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType, InputHTMLAttributes } from 'vue'

export default defineComponent({
    inheritAttrs: false,

    props: {
        id: String,
        type: String as PropType<InputHTMLAttributes['type']>,
        label: String,
        placeholder: String,
        value: String,
        modelValue: String,
        icon: String,
        iconExt: String
    },

    emits: [
        'input',
        'update:modelValue',
        'keyup',
        'keydown',
        'focus',
        'blur'
    ],

    computed: {
        fieldValue() {
            return this.modelValue ?? this.$props.value
        }
    },

    methods: {
        imageSrc(icon: string, ext?: string): string {
            if (!ext) return '.png'
            return new URL(`../assets/icons/${icon}.${ext}`, import.meta.url).href
        },
        emitInputEvents($event) {
            this.$emit('input', $event)
            this.$emit('update:modelValue', $event.target.value)
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss";

.meta, .field, .extra {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
}
.meta {
    & + .field {
        margin-top: 8px;
    }
}
.field, .extra, .hints {
    & + .meta,
    & + .field {
        margin-top: 24px;
    }
}
.field, .hints {
    & + .field,
    & + .extra > .field {
        margin-top: 16px;
    }
}

label {
    align-self: start;
    color: var(--neutral-50);
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;

    @include kit.mq-xsmall {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
    }
}

.field {
    display: flex;
    flex-direction: row;
    align-items: center;
}

input {
    @extend .input;

    font-size: 18px;
    height: 56px;
    padding: 16px;

    &:active, &:focus {
        outline: none;
    }

    &.invalid {
        border-bottom: 2px solid var(--red-300);
    }

    & + .adornments {
        height: auto;
    }
}

.flag {
    position: absolute;
    max-width: 100%;
    width: 100%;
    right: 0px !important;
    bottom: 100% !important;
}

.adornments {
    display: block;
    position: absolute;
    right: 0;
    flex: content;
    flex-direction: row;
    gap: 0.2em;
    margin: 0 0 2px;
    padding: 0 1em;
}

.hints {
    display: flex;
    flex-direction: row;
    margin-top: 0.5em;
    width: 100%;
    text-align: left;
}
</style>
